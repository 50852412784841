import React, { useState, useRef, useEffect } from "react";
import "./CSS/Initiatives.css";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";

import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const Initiatives_Genesis = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [initiatives, setInitiatives] = useState(null);

  const tabsRef = useRef(null);
  const tabs = [
    "Physiological Health",
    "Spiritual Health",
    "Financial Health",
    "Career Health",
    "Business Health",
    "Digital & Tech Health",
  ];

  useEffect(() => {
    const fetchInitiatives = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-initiatives`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInitiatives(res.data.data);
          console.log("Initiatives", res.data.data);
        })
        .catch((err) => console.log(err));
    };

    fetchInitiatives();
  }, []);

  // const content = [
  //   {
  //     lever: [
  //       {
  //         initiativename: "Industry Partnerships and Apprenticeships",
  //         levername: "Attracting",
  //         description:
  //           "Form partnerships with industry leaders and launch apprenticeship programs to attract young talent and provide hands-on experience.",
  //         outcome:
  //           "The company will be viewed as a hub for learning and career growth, increasing its attractiveness to high-potential candidates.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Bring-A-Friend Program",
  //         levername: "Attracting",
  //         description:
  //           "A referral program where employees can recommend friends for open positions, with incentives for successful hires.",
  //         outcome:
  //           "Attract high-quality candidates through trusted networks, improving recruitment success and reducing hiring costs.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Career Pathing Workshops",
  //         levername: "Hiring",
  //         description:
  //           "During hiring, show candidates a clear career growth path by presenting the workshops and personal development opportunities available.",
  //         outcome:
  //           "Better attraction and retention of talent, as candidates see a long-term future with the company.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Candidate Experience Surveys",
  //         levername: "Hiring",
  //         description:
  //           "Send out surveys to candidates after interviews to gather feedback on their hiring experience.",
  //         outcome:
  //           "Improve the recruitment process by gaining insights from candidates, leading to a more seamless and attractive hiring experience.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Next-Gen Virtual Orientation",
  //         levername: "Welcoming",
  //         description:
  //           "Provide a modern, virtual onboarding experience with engaging content, interactive platforms, and gamified learning modules for new hires.",
  //         outcome:
  //           "A seamless, engaging onboarding experience, especially for remote employees, helping them integrate faster.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Employee Onboarding Buddy Program",
  //         levername: "Welcoming",
  //         description:
  //           "Pair new employees with a buddy who can guide them through the first few weeks, making them feel welcome and supported.",
  //         outcome:
  //           "New hires will feel more comfortable and integrated into the team, reducing onboarding time and improving early retention.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Welcome Kit",
  //         levername: "Welcoming",
  //         description:
  //           "Provide all new employees with a personalized welcome kit that includes branded merchandise, company values, and essential information to help them integrate smoothly into the workplace.",
  //         outcome:
  //           "Enhances the first impression of the company, creating excitement and making new hires feel valued from day one.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Regular Feedback Cadence",
  //         levername: "Welcoming",
  //         description:
  //           "Implement a structured, continuous feedback system where managers provide quick, real-time feedback rather than waiting for annual or bi-annual reviews. Consider weekly check-ins or project-based feedback sessions.",
  //         outcome:
  //           "Employees will receive timely feedback that helps them grow and improve continuously, making performance development more agile.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Lunch n Learn",
  //         levername: "Inspiring",
  //         description:
  //           "Regular workshops that encourage leadership to inspire employees through empathetic leadership and clear communication.",
  //         outcome:
  //           "Motivated teams that are inspired by leaders who value their input and encourage personal and professional growth.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Hackathons for Architectural Innovation",
  //         levername: "Inspiring",
  //         description:
  //           "Organize hackathons to challenge employees to solve creative architectural problems, fostering innovation and inspiring them to experiment with new designs.",
  //         outcome:
  //           "Employees are inspired to push boundaries in their work, leading to greater creativity and job satisfaction.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Leadership Q&A Sessions",
  //         levername: "Inspiring",
  //         description:
  //           "Organize sessions where employees can ask leadership questions about the company's vision, goals, and plans in an open forum.",
  //         outcome:
  //           "Employees feel more connected to leadership, which increases trust and alignment with company objectives.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Coffee with CEO",
  //         levername: "Inspiring",
  //         description:
  //           "New hires can participate in informal meetings with the CEO, allowing them to understand the company’s vision directly from the leadership.",
  //         outcome:
  //           "Builds a welcoming environment where new employees feel connected to leadership early in their journey.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Fail Forward Workshops",
  //         levername: "Inspiring",
  //         description:
  //           "Offer workshops and training sessions focused on the concept of 'failing forward', where mistakes are framed as necessary steps toward innovation and improvement. Encourage leadership to share personal stories of failure and lessons learned.",
  //         outcome:
  //           "This will create a culture where employees are encouraged to take risks and learn from their mistakes, leading to continuous innovation and improvement.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Employee Brand Ambassadors",
  //         levername: "Inspiring",
  //         description:
  //           "Identify and empower employees to act as brand ambassadors. Provide them with the tools and messaging to share positive stories about the company externally, such as on social media or at industry events.",
  //         outcome:
  //           "Employees will help build the company’s reputation by sharing their positive experiences, increasing brand loyalty and visibility.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Regular Newsletters",
  //         levername: "Speaking",
  //         description:
  //           "Distribute newsletters to keep employees updated on company news, project progress, and upcoming events.",
  //         outcome:
  //           "Improved internal communication and collaboration, as employees stay informed and aligned with company goals.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Video Newsletters",
  //         levername: "Speaking",
  //         description:
  //           "Send out engaging video newsletters highlighting important updates and featuring key employee contributions.",
  //         outcome:
  //           "Strengthens engagement by delivering important information in a more accessible and engaging format.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Town Hall",
  //         levername: "Speaking",
  //         description:
  //           "Provide employees with a platform to raise concerns and feedback during regular town halls, fostering a transparent and open communication channel for grievances.",
  //         outcome:
  //           "Faster grievance resolution, as employees feel they have a direct line of communication with leadership.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Client Feedback Loops",
  //         levername: "Speaking",
  //         description:
  //           "Regularly solicit feedback from clients on design projects, ensuring employees understand the impact of their work.",
  //         outcome:
  //           "Enhances communication with clients and informs employees of areas for improvement, leading to better project outcomes and client satisfaction.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Peer-to-Peer Learning",
  //         levername: "Collaborating",
  //         description:
  //           "Encourage collaboration through structured peer-learning sessions where employees exchange knowledge and skills.",
  //         outcome:
  //           "Enhanced teamwork, knowledge sharing, and stronger bonds between departments, leading to a collaborative culture.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Cross-Department Collaboration",
  //         levername: "Collaborating",
  //         description:
  //           "Encouraging cross-department collaboration leads to creative problem-solving and innovative project solutions.",
  //         outcome:
  //           "Breaks down silos and fosters teamwork, resulting in more comprehensive and innovative project outcomes.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Flat Organizational Structure",
  //         levername: "Collaborating",
  //         description:
  //           "Encouraging a flat hierarchy promotes open communication and collaboration between all levels of the organization.",
  //         outcome:
  //           "Leads to faster decision-making, improved team collaboration, and a more agile working environment.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Lunch Buddies or Random Coffee Initiatives",
  //         levername: "Collaborating",
  //         description:
  //           "Encourage random pairings of employees from different teams to meet for coffee or lunch, fostering cross-departmental relationships.",
  //         outcome:
  //           "This will improve communication and collaboration between teams, helping employees feel more connected across the company.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Lessons learnt sessions",
  //         levername: "Collaborating",
  //         description:
  //           "After completing major projects, conduct lesson learnt sessions where teams review what went well and where mistakes occurred. The focus should be on collective learning rather than placing blame.",
  //         outcome:
  //           "Teams will learn from each project, improving future performance and fostering a supportive culture where learning is prioritized over blame.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Wall of Fame",
  //         levername: "Thanking",
  //         description:
  //           "Create a dedicated space to celebrate employee achievements, highlighting outstanding contributions and innovations.",
  //         outcome:
  //           "Public recognition boosts morale and encourages others to strive for excellence, reinforcing a culture of appreciation.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Client Recognition of Designers",
  //         levername: "Thanking",
  //         description:
  //           "Allow clients to publicly recognize employees for their contributions to design projects.",
  //         outcome:
  //           "Enhances employee motivation by providing external validation of their work, building strong client-employee relationships.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Outcome-Driven Job Descriptions",
  //         levername: "Developing",
  //         description:
  //           "Job descriptions that focus on measurable outcomes rather than just responsibilities attract goal-oriented candidates who align with the company's objectives.",
  //         outcome:
  //           "Improves the quality of candidates during the hiring process by clearly outlining expectations and measurable goals.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Design Thinking Fellowships",
  //         levername: "Developing",
  //         description:
  //           "Offering fellowships in design thinking encourages employees to explore innovative design methodologies.",
  //         outcome:
  //           "Drives creative problem-solving and enhances the company’s ability to deliver innovative design solutions.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Career Path and Learning Passport",
  //         levername: "Developing",
  //         description:
  //           "Provide a structured development plan, outlining the necessary skills and training for each role, so employees can map out their growth.",
  //         outcome:
  //           "Employees feel empowered to take ownership of their career development, leading to higher engagement and retention.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Job Rotation Programs",
  //         levername: "Developing",
  //         description:
  //           "Employees rotate through different roles or departments to broaden their skill set and gain a holistic understanding of the company’s operations.",
  //         outcome:
  //           "Enhances employee versatility and promotes career development, reducing burnout and increasing employee retention.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "BIM Skill Development Programs",
  //         levername: "Developing",
  //         description:
  //           "Offer programs focused on Building Information Modeling (BIM) to enhance technical expertise in cutting-edge software and processes.",
  //         outcome:
  //           "Develops critical technical skills, allowing employees to remain competitive and deliver innovative architectural solutions.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Leadership Shadowing",
  //         levername: "Developing",
  //         description:
  //           "Provide opportunities for employees to shadow senior leaders, learning leadership skills and organizational strategies.",
  //         outcome:
  //           "Cultivates leadership capabilities in employees, creating a pipeline for future leaders and enhancing organizational succession planning.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Internal Knowledge-Sharing Platforms",
  //         levername: "Developing",
  //         description:
  //           "Create platforms where employees can share knowledge, resources, and expertise within the company.",
  //         outcome:
  //           "Enhances collaboration and continuous learning, ensuring that employees feel supported in their professional development.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Redesign the Performance Review Process",
  //         levername: "Developing",
  //         description:
  //           "Create a clear, objective, and consistent performance review process that includes regular feedback, self-assessments, and peer reviews. Align performance with clear, measurable goals.",
  //         outcome:
  //           "This will make performance evaluations more fair and transparent, leading to better alignment with goals and promoting professional growth.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Quarterly Career Conversations",
  //         levername: "Developing",
  //         description:
  //           "Hold regular career development conversations between employees and managers, focusing on how employees can apply their skills to new challenges and growth opportunities.",
  //         outcome:
  //           "Employees will have more opportunities for career growth and development by discussing their goals and finding new challenges to pursue.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Review Attendance Policies",
  //         levername: "Balancing",
  //         description:
  //           "Modernized and flexible attendance policies make the company more appealing to prospective employees, ensuring a supportive work environment.",
  //         outcome:
  //           "Attracts talent by offering flexibility, promoting work-life balance, and ensuring inclusivity for all employees.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Design Retreats",
  //         levername: "Balancing",
  //         description:
  //           "Organize retreats focused on fostering creativity, team bonding, and relaxation, contributing to employees’ work-life balance.",
  //         outcome:
  //           "Reduces stress, increases creative output, and strengthens team cohesion by providing employees a break from the routine.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Fun Fridays & Wellness Days",
  //         levername: "Balancing",
  //         description:
  //           "Organize monthly events focused on team-building and relaxation to celebrate achievements and provide mental health breaks.",
  //         outcome:
  //           "A happier workforce with higher morale, as employees feel celebrated and valued outside of their work roles.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Employee-Centric Policy Review",
  //         levername: "Including",
  //         description:
  //           "Involve employees in reviewing and updating policies that affect their well-being and professional growth.",
  //         outcome:
  //           "Employees feel included in decision-making processes, which fosters trust and a sense of ownership in the company.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Sanitary Pads in the Washroom",
  //         levername: "Including",
  //         description:
  //           "Provide sanitary pads in washrooms, promoting inclusivity and ensuring that female employees feel supported and valued.",
  //         outcome:
  //           "Demonstrates the company’s commitment to a supportive and inclusive environment, fostering a sense of belonging.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Female New Joinees Special Welcome",
  //         levername: "Including",
  //         description:
  //           "Female new joinees are welcomed with a special package that reflects the company’s commitment to diversity and inclusion.",
  //         outcome:
  //           "Ensures female employees feel included and supported from the moment they join the organization.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Flat Organizational Structure",
  //         levername: "Including",
  //         description:
  //           "A flat hierarchy ensures that all employees, regardless of their level, are included in decision-making processes.",
  //         outcome:
  //           "Creates a more inclusive and democratic workplace where all employees feel valued and heard.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Diversity Celebrations",
  //         levername: "Including",
  //         description:
  //           "Celebrate different cultural, gender, and social backgrounds through dedicated events and employee resource groups (ERGs) that create awareness and inclusivity.",
  //         outcome:
  //           "This will raise awareness and create a more inclusive workplace where all employees feel valued and respected.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Heroes of Genesis Planner",
  //         levername: "Celebrating",
  //         description:
  //           "An internal program to recognize and celebrate employees who have made significant contributions to the company.",
  //         outcome:
  //           "Employees feel valued and appreciated, which strengthens loyalty and motivates others to excel.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Celebrating Birthdays",
  //         levername: "Celebrating",
  //         description:
  //           "A simple celebration for employees on their birthdays to foster work-life balance and promote well-being by acknowledging important personal milestones.",
  //         outcome:
  //           "Employees feel personally valued, which contributes to a happier workplace and better employee morale.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "Celebrate Milestones and Contributions",
  //         levername: "Celebrating",
  //         description:
  //           "Welcoming new employees and celebrating their initial contributions fosters a sense of belonging from the start.",
  //         outcome:
  //           "New hires feel appreciated and motivated to contribute meaningfully early on, improving employee engagement and retention.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Low",
  //       },
  //       {
  //         initiativename: "In-House Competitions",
  //         levername: "Celebrating",
  //         description:
  //           "Organize internal design competitions that celebrate creativity and innovation within the company.",
  //         outcome:
  //           "Encourages friendly competition and showcases employee talent, leading to enhanced morale and job satisfaction.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Cross-Team Competitions",
  //         levername: "Celebrating",
  //         description:
  //           "Host competitions between different teams to foster collaboration and innovation across the organization.",
  //         outcome:
  //           "Encourages teamwork and builds relationships across departments, leading to stronger collaboration on real-world projects.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Monthly Social Events",
  //         levername: "Celebrating",
  //         description:
  //           "Organize casual monthly social events like lunch-and-learns, game nights, or after-work get-togethers to promote bonding in a relaxed setting.",
  //         outcome:
  //           "These events will help build stronger team bonds and increase employee engagement through fun and relaxed interactions.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Gamification of Daily Tasks",
  //         levername: "Rewarding",
  //         description:
  //           "Introduce game mechanics into daily work activities, rewarding employees for meeting targets and contributing to company success.",
  //         outcome:
  //           "Increased motivation and engagement as employees are consistently rewarded for their hard work and accomplishments.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Recognizing and Rewarding Contributions",
  //         levername: "Rewarding",
  //         description:
  //           "A structured approach to reward employees for their achievements through bonuses, public recognition, promotions, and other rewards.",
  //         outcome:
  //           "Increased job satisfaction and employee retention, as contributions are consistently acknowledged and rewarded.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Achievement Box",
  //         levername: "Rewarding",
  //         description:
  //           "Set up achievement boxes in all offices where employees can submit notes about personal achievements or appreciate a colleague’s work. Senior employees open the boxes during visits and recognize contributions publicly.",
  //         outcome:
  //           "Creates a culture of achievement and recognition, fostering camaraderie and boosting morale across all levels of the organization.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "Medium",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Design Innovation Awards",
  //         levername: "Rewarding",
  //         description:
  //           "Recognizing cutting-edge architectural designs with awards fosters creativity and innovation in the workplace.",
  //         outcome:
  //           "Employees are inspired to push creative boundaries, resulting in groundbreaking design solutions.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Public Showcases of Employee Work",
  //         levername: "Rewarding",
  //         description:
  //           "Celebrate and display employee achievements through public exhibitions, events, or showcases.",
  //         outcome:
  //           "Provides recognition for employee contributions, motivating continued excellence and pride in their work.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Open-Door Leadership Program",
  //         levername: "Grievance",
  //         description:
  //           "Establish a leadership program where employees can directly raise concerns with senior management in a non-hierarchical setting.",
  //         outcome:
  //           "Faster resolution of grievances, as employees feel heard and valued, which minimizes dissatisfaction and turnover.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Digital Suggestion Box (The HOPE Box)",
  //         levername: "Grievance",
  //         description:
  //           "Implement an anonymous digital suggestion box for employees to submit feedback, grievances, or suggestions for improvement.",
  //         outcome:
  //           "Encourages employees to share their concerns and ideas without fear of repercussion, leading to a more open and responsive culture.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Code of Ethics and Conduct Training",
  //         levername: "Grievance",
  //         description:
  //           "Regularly reinforce the company’s ethical standards by conducting mandatory annual training on the code of ethics. Ensure all employees understand the company’s values and the importance of ethical behavior.",
  //         outcome:
  //           " Employees will have a stronger understanding of the company’s values, leading to higher ethical standards and responsible behavior.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Innovation Challenges",
  //         levername: "Contributing",
  //         description:
  //           "Allow employees to contribute to the company's growth by presenting new ideas and improvements through structured innovation challenges.",
  //         outcome:
  //           "Employees feel a sense of ownership and pride in contributing to the company’s success, boosting engagement.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Carbon-Neutral Design Initiatives",
  //         levername: "Contributing",
  //         description:
  //           "Encourage employees to contribute to projects that focus on sustainability and carbon-neutral architecture.",
  //         outcome:
  //           "Engages employees in meaningful work that addresses climate change, aligning their contributions with the company's environmental goals.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Architectural Outreach Programs",
  //         levername: "Contributing",
  //         description:
  //           "Involve employees in socially impactful design projects that contribute to the community.",
  //         outcome:
  //           "Fosters a sense of purpose and social responsibility, driving employee engagement and community impact.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Health and Well-Being Initiatives",
  //         levername: "Supporting",
  //         description:
  //           "Offer physical and mental health programs, including fitness challenges, wellness apps, and mental health resources, to promote work-life balance.",
  //         outcome:
  //           "A healthier, more engaged workforce with reduced stress levels and increased productivity.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Simple Interventions like Family Day",
  //         levername: "Supporting",
  //         description:
  //           "Organize events such as Family Day, where employees' families are invited for celebrations, competitions, and team-building activities.",
  //         outcome:
  //           "Strengthened work-life balance and family inclusion, leading to improved employee morale and satisfaction.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Financial Wellness Programs",
  //         levername: "Supporting",
  //         description:
  //           "Offer financial planning tools and resources, such as workshops and advisor sessions, to help employees manage their finances effectively.",
  //         outcome:
  //           "Reduced financial stress leads to greater focus at work, higher job satisfaction, and improved overall well-being.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "AI-Powered Chatbots for HR Policies",
  //         levername: "Supporting",
  //         description:
  //           "Deploy AI-powered chatbots to provide instant answers to employees’ questions about HR policies and processes.",
  //         outcome:
  //           "Streamlined HR support leads to faster resolutions for employee queries, improving overall efficiency and satisfaction.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Pre-Retirement Counseling",
  //         levername: "Supporting",
  //         description:
  //           "Offer counseling and workshops for employees approaching retirement to help them plan their post-work life, including financial planning and emotional well-being.",
  //         outcome:
  //           "Employees feel supported throughout their career lifecycle, fostering loyalty and reducing anxiety around retirement.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Medical Health Insurance",
  //         levername: "Supporting",
  //         description:
  //           "Offering comprehensive medical insurance as part of the benefits package ensures the company remains competitive in attracting talent.",
  //         outcome:
  //           "Prospective employees are attracted by robust health benefits, leading to higher recruitment rates and employee retention.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "One-Stop Benefits Portal",
  //         levername: "Supporting",
  //         description:
  //           "Create a centralized, user-friendly benefits portal where employees can easily access all information related to health benefits, retirement plans, wellness programs, and more. Ensure that the portal is mobile-friendly for ease of use.",
  //         outcome:
  //           "Employees will find it easier to access and understand their benefits, which will increase their usage and overall satisfaction with the benefits offered.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "HR Support Channels",
  //         levername: "Supporting",
  //         description:
  //           "Offer a dedicated HR helpdesk or chatbot where employees can ask questions and get instant answers about their benefits. This will help reduce confusion and ensure benefits are easily accessible when needed.",
  //         outcome:
  //           "Employees will get quicker and clearer answers to their HR-related questions, improving their experience with the company’s benefits and policies.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Personalized HR Support",
  //         levername: "Supporting",
  //         description:
  //           "Encourage HR to provide personalized assistance to employees navigating difficult times. This might include connecting them with counseling, financial planning, or legal support services.",
  //         outcome:
  //           "Employees will feel more supported during difficult times, improving their well-being and overall satisfaction with the company.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Culture Pulse Quarterly Surveys",
  //         levername: "Listening",
  //         description:
  //           "Conduct regular pulse surveys to gauge employee sentiment and gather insights on how to continuously improve the culture.",
  //         outcome:
  //           "Leadership gains real-time feedback, which helps address concerns quickly and build a culture based on employee input.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Bi-Annual Employee Listening Forums",
  //         levername: "Listening",
  //         description:
  //           "Structured forums where employees can share feedback with leadership on issues like work processes, mental health, and company policies.",
  //         outcome:
  //           "Leadership gains valuable insights, and employees feel their voices are heard, leading to higher trust and a better work environment.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Anonymous Feedback Tool",
  //         levername: "Listening",
  //         description:
  //           "Introduce a digital platform where employees can voice concerns, suggestions, or feedback anonymously.",
  //         outcome:
  //           "Empowered employees feel safe to share opinions, leading to actionable feedback for continuous improvement.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Listening Circles",
  //         levername: "Listening",
  //         description:
  //           "Regular small-group discussions where employees can openly share their experiences and feedback with peers and management.",
  //         outcome:
  //           "Employees feel heard, and the company gains valuable insights to improve culture and processes.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Open Door Policy",
  //         levername: "Listening",
  //         description:
  //           "Encourage an open-door policy where employees feel comfortable discussing issues or ideas directly with management.",
  //         outcome:
  //           "Increased trust and communication between employees and leadership, fostering a transparent workplace.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Structured Feedback Loops",
  //         levername: "Listening",
  //         description:
  //           "Implement continuous feedback mechanisms that ensure employee concerns are collected and addressed regularly.",
  //         outcome:
  //           "Creates an open platform for addressing grievances, reducing frustration, and improving employee satisfaction.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Peer Feedback Mechanisms",
  //         levername: "Listening",
  //         description:
  //           "Introduce peer-to-peer feedback opportunities where employees can offer insights and praise to their colleagues. This provides a 360-degree view of performance and creates a more collaborative, supportive environment.",
  //         outcome:
  //           "This will create a culture of collaboration and support where employees can grow and improve through feedback from their peers.",
  //         duration: "Ongoing",
  //         cost: "N/A",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  // ];

  // const content = [
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Healthy",
  //         levername: "Physiological Health",
  //         description:
  //           "Initiative proposes a multifaceted approach to support employees' physiological health through the integration of wellbeing apps, collective wellbeing practices, and wellbeing-linked rewards. The initiative aims to promote habit formation and overall health, reinforce physical wellbeing through community activities, and align rewards with health-promoting experiences and services. This holistic approach ensures that employees have the resources, support, and incentives needed to maintain and improve their physical and mental health.",
  //         outcome:
  //           "Employees would develop healthier habits, leading to improved physical and mental wellbeing, reduced stress levels, and enhanced overall quality of life.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Fit",
  //         levername: "Physiological Health",
  //         description:
  //           "Wellbeing Initiative proposes enhancing employees' physical health by promoting regular exercise, raising awareness of physical wellbeing, and offering resources and programs dedicated to wellness. This initiative involves collaborating with professional physical trainers, offering diverse fitness activities, sharing informative materials, initiating workplace movement challenges, advocating for regular breaks, scheduling medical checkups, providing access to health screenings, organizing sports events, and establishing a company sports club.",
  //         outcome:
  //           "Encouraging regular physical activity boosts energy levels, enhances productivity, promotes healthy lifestyle choices, and contributes to a more active workforce. ",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "High",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Comes Together",
  //         levername: "Spiritual Health",
  //         description:
  //           "The 'Become Comes Together' initiative aims to strengthen employees' connection to the company’s mission by organizing community service events and social impact projects. This initiative will involve partnerships with local non-profits and social organizations to create ongoing volunteering opportunities. Additionally, a platform will be created for employees to propose and lead their own social impact projects, with resources and support provided by the company.",
  //         outcome:
  //           "Increased employee fulfillment, stronger alignment with the Become’s mission, enhanced community impact, and improved employee morale. Employees will feel more connected to the company’s mission and values, leading to higher engagement and satisfaction.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Volunteering Time Off (VTO",
  //         levername: "Spiritual Health",
  //         description:
  //           "This initiative provides employees with paid time off to volunteer for social causes they are passionate about. Employees can choose from a list of approved non-profits or propose their own volunteering opportunities. The initiative includes a comprehensive policy outlining the program’s vision, objectives, and scope. Employees are required to submit a report detailing their contributions and experiences after their volunteering activities. The program aims to foster a sense of purpose, enhance community engagement, and support the company’s mission of societal impact.",
  //         outcome:
  //           "Employees will feel more connected to the Become’s mission, leading to increased fulfillment and morale. The initiative will promote a culture of giving back and community involvement, enhancing the company's reputation and employee satisfaction. Additionally, it will support personal growth and development through diverse volunteering experiences.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Financially Secure",
  //         levername: "Financial Health",
  //         description:
  //           "Finance Drives will provide employees with access to better financial wellbeing through targeted support in four key areas: insurance, savings, investment, and financial management. This initiative will include monthly workshops conducted by financial experts who will provide practical advice and actionable steps. Employees will also receive resource materials such as brochures, guides, and online resources to support their financial decisions. Additionally, one-on-one consultations with financial advisors will be available quarterly to offer personalized guidance based on individual financial needs.",
  //         outcome:
  //           "Employees will have better access to information and tools to improve their financial health, leading to reduced financial stress, increased financial security, and enhanced overall wellbeing.",
  //         duration: "6 month",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Financially Literate",
  //         levername: "Financial Health",
  //         description:
  //           "Awareness and Specialty Sessions will focus on improving employees' financial awareness and management skills through a series of comprehensive education sessions. These sessions will cover essential topics such as budgeting, saving, investing, and emergency fund preparation. Monthly group workshops will encourage interactive learning and peer support, while quarterly one-on-one sessions will provide personalized financial advice tailored to individual employees' needs. The content will be customized based on the average salary slabs within the company to ensure relevance and applicability. Practical exercises and real-life examples will be included to enhance learning outcomes.",
  //         outcome:
  //           "Employees will gain better financial management skills, be better prepared for financial emergencies, and improve their savings practices. This will lead to improved financial health, reduced financial stress, increased employee satisfaction, and higher retention rates.",
  //         duration: "6 months",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },

  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Recognised",
  //         levername: "Career Health",
  //         description:
  //           "Rewards & Recognition Program will focus on rewarding and recognising employees' efforts, behaviours, values, contributions, personal and professional achievements, and performances. This initiative will include monthly awards, spot bonuses, and public recognition in company meetings and newsletters. Employees will be nominated by their peers and managers, and a committee will evaluate the nominations to ensure fairness and consistency. Additional perks such as extra paid leave days, gift vouchers, and professional development opportunities will also be included.",
  //         outcome:
  //           "Employees will feel valued and appreciated, leading to increased motivation, job satisfaction, and loyalty. This will foster a positive work environment and enhance overall performance and productivity.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },

  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Gamified",
  //         levername: "Business Health",
  //         description:
  //           "Gamified Performance Programs proposes using gamification techniques to build a comprehensive performance program within the organisation. This initiative will involve designing interactive and engaging methods to track and enhance employee performance. Elements such as points, badges, leaderboards, and rewards will be incorporated to motivate employees and promote healthy competition. Regular feedback and performance reviews will be gamified to ensure continuous improvement and engagement.",
  //         outcome:
  //           "Employees will be more motivated and engaged, leading to improved performance, productivity, and job satisfaction.",
  //         duration: "6-12 months",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Streamlined",
  //         levername: "Business Health",
  //         description:
  //           "Systems and Processes Enhancement proposes conducting comprehensive reviews of existing systems and processes, implementing enhancements to streamline workflows, and increasing overall efficiency. Additional training for management to improve accessibility. Regular benchmarking of benefits will also be included to ensure competitiveness and employee satisfaction.",
  //         outcome:
  //           "The initiative will result in smoother workflows, increased efficiency, improved satisfaction, and enhanced overall business performance. Employees will benefit from clearer processes, and a better resource allocation",
  //         duration: "Ongoing monitoring and adjustments",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Advanced",
  //         levername: "Digital & Tech Health",
  //         description:
  //           "This initiative aims to enhance employees' physiological health by encouraging regular physical activity, increasing awareness of physical health, and providing resources and programs focused on well-being. The initiative will include fitness sessions with a physical trainer, health seminars, workplace movement challenges, medical checkups, and sports activities. Regular fitness sessions will be conducted by a professional trainer, covering various activities like yoga, strength training, and cardio exercises. Health seminars will raise awareness about the importance of physical health and provide tips on maintaining a healthy lifestyle. Workplace movement challenges and sports activities will encourage regular participation in physical activities.",
  //         outcome:
  //           "Improved physical health, higher energy levels, enhanced productivity, and better overall employee well-being. Employees will be more active and energetic, leading to a healthier, more productive workforce.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "High",
  //       },
  //     ],
  //   },
  // ];

  const scrollTabs = (direction) => {
    const scrollAmount = 200; // Adjust scroll amount as needed
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        left: "18%",
        width: "65vw",
      }}
    >
      <div style={{ width: "60vw", backgroundColor: "#CFCFCF" }} />
      <p style={{ fontWeight: "510", fontSize: "20px" }}>Initiatives</p>
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />

      {/* <button
          onClick={() => scrollTabs("left")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8592; {/* Left arrow 
        </button> */}
      <div
        className="tabs"
        style={{
          width: "65vw",
          overflow: "auto",
        }}
        ref={tabsRef}
      >
        {initiatives !== null &&
          initiatives.map((tab, index) => (
            <div
              key={index}
              className={`tab-button ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#0042A6",
                borderRadius: "8px",
                width: "200px",
                height: "20px",
                margin: "10px",
                color: `${activeTab === index ? "#fff06b" : "#fff"}`,
                whiteSpace: "nowrap",
              }}
            >
              <p style={{ width: "100%", fontSize: "14px" }}>
                {tab.lever[0].levername}
              </p>
            </div>
          ))}
      </div>
      <div
        style={{
          width: "65vw",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{ cursor: "pointer", marginRight: "15px" }}
          onClick={() => scrollTabs("left")}
        >
          <MdKeyboardArrowLeft size={30} color="#000" />
        </div>
        <div style={{ cursor: "pointer" }} onClick={() => scrollTabs("right")}>
          <MdKeyboardArrowRight size={30} color="#000" />
        </div>
      </div>

      {/* <button
          onClick={() => scrollTabs("right")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8594;
        </button> */}

      <div style={{ width: "62vw" }} className="tab-content">
        {initiatives !== null &&
          initiatives[activeTab].lever.map((lever, leverIndex) => (
            <div>
              {/* <div
                style={{
                    backgroundColor: "#fff06b",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  top: "-20px",
                }}
              > */}
              <p
                style={{
                  fontWeight: "800",
                  fontSize: "25px",
                  color: "#0042A6",
                }}
              >
                {lever.initiativename}
              </p>
              {/* </div> */}
              <p style={{ fontWeight: "700" }}>What we can do?</p>
              <div style={{ marginTop: "-15px" }}>
                {lever.description}
                {/* <ul>
                  {lever.description.map((item) => (
                    <li>{item.desc}</li>
                  ))}
                </ul> */}
              </div>
              <p style={{ fontWeight: "700" }}>
                What can you expect as an outcome?
              </p>
              <div style={{ marginTop: "-15px" }}>
                {lever.outcome}
                {/* <ul>
                  {lever.outcome.map((item) => (
                    <li>{item.out}</li>
                  ))}
                </ul> */}
              </div>

              {/* <div className="grid-view">
                <div className="grid-item">Duration: {lever.duration}</div>
                <div className="grid-item">Cost: {lever.cost}</div>
                <div className="grid-item">Impact: {lever.impact}</div>
                <div className="grid-item">Effort: {lever.effort}</div>
              </div> */}
              {initiatives !== null &&
                initiatives[activeTab].lever.length !== 0 &&
                leverIndex !== initiatives[activeTab].lever.length - 1 && (
                  <div
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0,0,0,0.2)",
                      }}
                    />
                  </div>
                )}
            </div>
          ))}
      </div>
    </div>
  );
};
//{content[activeTab]}

export default Initiatives_Genesis;
